<template>
    <div class="auto-1440">
        <Breadcrumb type="grey" />
        <a-spin :spinning="loading">
            <div class="title_wrap">
                <h1>
                    <svg-icon icon-class="icon_publish"></svg-icon>
                    <span>发布您的新帖</span>
                </h1>
                <div class="btn_wrap">
                    <button @click="$router.go(-1)">取消</button>
                    <button class="orange" @click="publish">发布</button>
                </div>
            </div>
            <a-form-model
                :model="form"
                ref="publishForm"
                class="form"
                :rules="rules"
            >
                <a-form-model-item prop="Title">
                    <div class="form-item">
                        <div class="label"><span class="red">* </span><span>标题</span></div>
                        <div class="input">
                            <a-input v-model="form.Title" placeholder="请输入您的标题" allowClear/>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="SubjectId">
                    <div class="form-item">
                        <div class="label"><span class="red">* </span><span>所属分类</span></div>
                        <div class="input w50">
                            <!-- <a-select placeholder="请选择所属分类">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option v-for="item in options" :key="item.value">
                                    {{item.name}}
                                </a-select-option>
                            </a-select> -->
                            <a-tree-select
                                style="width: 100%"
                                :tree-data="subjectList"
                                placeholder="请选择所属分类"
                                tree-default-expand-all
                                :replaceFields="replaceFields"
                                v-model="form.SubjectId"
                            >

                            </a-tree-select>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item >
                    <div class="form-item">
                        <div class="label"><span class="red" style="opacity:0">* </span><span>关联模型</span></div>
                        <div class="input w50">
                            <a-select 
                                mode="multiple" 
                                placeholder="请选择需要关联的模型" 
                                :dropdownMenuStyle="{ maxHeight: '280px',padding:'10px',height:'280px' }"
                                @search="modelListSearch"
                                :filter-option="false"
                                v-model="ModelIds"
                            >
                                
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <div class="page" @mousedown="e => e.preventDefault()">
                                        <Pagination 
                                            size='small'
                                            :pageSize="modelParams.pageSize"
                                            :total="total"
                                            @pageChange="pageChange"
                                            v-if="modelList.length>0"    
                                        />
                                    </div>
                                </div>
                                <a-select-option v-for="item in checkModelList" :key="'check' +  item.Id" :value="item.Id" hidden>
                                    {{item.Name}}
                                </a-select-option>
                                <a-spin v-if="modelLoading" slot="notFoundContent" size="small" />
                                <template v-else>
                                    <a-select-option v-for="item in modelList" :key="'option' + item.Id" :value="item.Id">
                                        {{item.Name}}
                                    </a-select-option>
                                </template>
                                
                                
                            </a-select>
                        </div>
                        <!-- <div class="switch"><a-switch /></div> -->
                        
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="Content" class="content">
                    <Editor v-model="form.Content" :isEditable="true" />
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        
        <TipDialog :visable="visable" @close="visable = false" :loading="confirmLoading" @confirm="confirmPublish"/>
    </div>
</template>
<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import Editor from '@/components/common/Editor'
import TipDialog from '@/components/common/TipDialog'
import Pagination from '@/components/common/Pagination'
import  { GetList , GetListByIds } from '@/api/model'
import  { Add , GetData , Update} from '@/api/forum'
export default {
    metaInfo() {
		return {
			title: this.$route.meta.title + '-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: this.$route.meta.title + '-文物出版社'
                },
                {
                    name: 'description',
					content: this.$route.meta.title + '-文物出版社'
                }
            ]
		}
	},
    components:{
        Breadcrumb,
        Editor,
        TipDialog,
        Pagination,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data() {
        return {
            form:{
                Title:'',
                SubjectId:null,
                ModelIds:'',
                Content:''
            },
            ModelIds:[],
            // 弹窗
            visable:false,
            // 分类
            subjectList:[],
            replaceFields:{children:'children', title:'Title', key:'Id', value: 'Id' },

            // rules
            rules:{
                Title:[ { required: true, message: '请输入标题', trigger: 'blur' } ],
                Content:[ { required: true, message: '请输入内容', trigger: 'blur' } ],
                SubjectId:[ { required: true, message: '请选择分类', trigger: 'change' } ],
            },

            // 模型搜索参数
            modelParams:{
                pageIndex:1,
                pageSize:8,
                title:""
            },
            total:0,
            modelList:[],
            checkModelList:[],
            modelLoading:false,


            // tijiao 
            confirmLoading:false,

            loading:false
        };
    },
    watch:{
        ModelIds:{
            handler(newVal,oldVal){
                this.form.ModelIds = JSON.stringify(newVal)
            }
        },
        modelList(val){
            if(this.$route.query.id){
                this.filterModelList()
            }
            
        },
        checkModelList(val){
            if(this.$route.query.id){
                this.filterModelList()
            }
        }
    },
    methods:{
        publish(){
            console.log(this.form);
            this.$refs.publishForm.validate(valid => {
                if (valid) {
                    this.visable = true
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
            // return
            
        },
        // 确认发布
        confirmPublish(){
            var scriptReg = /(<|&lt;)script.*script(>|&gt;)/
            this.form.Content = this.form.Content.replace(scriptReg,"");
            this.confirmLoading = true
            if(!this.$route.query.id){
                // 发帖
                Add(this.form).then(res => {
                    console.log(res);
                    this.$message.success(res.msg)
                    this.confirmLoading = false
                    this.visable = false
                    this.form.ModelIds = ''
                    this.$refs.publishForm.resetFields()
                    this.$router.replace({name:'forum-list'})
                })
            }else{
                
                Update(this.form).then(res => {
                    console.log(res);
                    this.$message.success(res.msg)
                    this.confirmLoading = false
                    this.visable = false
                    this.form.ModelIds = ''
                    this.$refs.publishForm.resetFields()
                    this.$router.go(-1)
                })
            }
            
        },
        // 循环树结构
        loopTreeData(list, callback) {
            ;(function doOneFloor(list) {
                if (Array.isArray(list)) {
                    for (let i = 0; i < list.length; i++) {
                        const item = list[i]
                        callback(item, i)
                        if (item.children.length > 0) {
                            doOneFloor(item.children)
                        }
                    }
                }
            })(list)
        },


        // 获取模型列表
        async getModleList(){
            this.modelLoading = true
            const { data , total  } =  await GetList(this.modelParams)
            this.modelList = data
            this.total = total
            this.modelLoading = false
            this.$forceUpdate()
            
        },
        pageChange( { page , pageSize} ){
            this.modelParams.pageIndex = page
            this.getModleList()
        },
        modelListSearch(value){
            if(this.modelLoading) return
            console.log(value);
            this.modelParams.title = value
            this.modelParams.pageIndex = 1
            this.getModleList()
        },
        // 获取帖子信息
        async getPostInfo(){
            this.loading = true
            const { data } = await GetData({id:this.$route.query.id})
            const { Id , Title , Content , SubjectId , ModelIds } = data
            this.form = {  Title , Content , SubjectId , ModelIds }
            this.$set(this.form , 'Id' , Id);
            this.ModelIds = this.form.ModelIds ?  JSON.parse(this.form.ModelIds) : []
            await this.getCheckModelList()
            this.loading = false
        },
        getCheckModelList(){
            return new Promise( ( resolve , reject ) => {
                GetListByIds({ids:this.form.ModelIds}).then(res => {
                    const { data } =  res 
                    this.checkModelList = data || []
                    resolve(data)
                }).catch(err => {
                    reject(err)
                })
                
            })
            
        },
        // 过滤选中模型列表
        filterModelList(){
            console.log(this.checkModelList);
            if(this.checkModelList.length == 0) return
            this.checkModelList.forEach( ( item , i) => {
                // console.log(this.modelList.findIndex(subitem => subitem.Id == item.Id),'index');
                if(this.modelList.findIndex(subitem => subitem.Id == item.Id) !== -1){
                    this.checkModelList.splice(i,1)
                }
            })
           
        }
        
    },
    async created(){
        this.subjectList = await this.$store.dispatch('model/getSubject')
        // this.loopTreeData(this.subjectList,(item) => {
        //     if(item.children && item.children.length > 0){
        //         item.selectable = false
        //     }
        // })
        this.getModleList();
        if(this.$route.query.id){
            this.getPostInfo()
        }
    }

}
</script>
<style lang="less" scoped>
:deep(.breadcrumb){
    border-bottom: 0;
}
.title_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #e5e5e5;
    h1{
        display: flex;
        align-items: center;
        font-size: 20px;
        color: rgb(51, 51, 51);
        font-weight: bold;
        .svg-icon{
            display: block;
            margin-right: 10px;
        }
    }
    .btn_wrap{
        display: flex;
        align-items: center;
        button{
            min-width: 120px;
            line-height: 40px;
            font-size: 18px;
            border-radius: 3px;
            margin-left: 10px;
            font-weight: bold;
            cursor: pointer;
            transition: .3s;
            &:hover{
                background-color: rgb(244, 145, 51);
                color: #fff;
                box-shadow: 0 0 10px 0 rgb(244, 145, 51 , .8);
            }
        }
        .orange{
            background-color: #f49133;
            color: #fff;
        }
    }
}
.form{
    padding-top: 40px;
    padding-bottom: 70px;
    .red{
        color: red;
    }
    .form-item{
        display: flex;
        .label{
            min-width: 140px;
            font-size: 20px;
            color: #000;
            line-height: 60px;
            font-weight: bold;
            flex-shrink: 0;
        }
        .input{
            width: 100%;
            &.w50{
                width: 700px;
            }
            :deep(.ant-select-search){
                line-height: 58px;
            }
        }

    }
    :deep(.ant-input){
        border-color: transparent;
        height: 58px;
        line-height: 58px;
        background-color: #f5f5f5;
        width: 100%;
        padding: 0 20px;
        font-size: 16px;
        color: rgb(136, 136, 136);
        &::placeholder{
            color: rgb(187, 187, 187);
        }
    }
    :deep(.ant-select-selection){
        max-height: 126px;
        overflow-y: auto;
        height: auto;
    }
    :deep(.ant-select-selection__rendered){
        height: 58px;
        line-height: 58px;
        color: rgb(136, 136, 136);
        margin: 0 10px;
       
        .ant-select-selection__choice{
            padding-right: 30px;
        }
    }
    :deep(.ant-select-selection__choice__remove){
        margin-left: 15px;
    }
    :deep(.ant-select-selection__choice){
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        border: 0;
        border-radius: 3px;
        background-color: #fff;
        .anticon-close{
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #d5d5d5;
            font-size: 8px;
            color: #fff;
        }
    }
    .switch{
        margin-left: 20px;
        height: 60px;
        display: flex;
        align-items: center;
    }
    :deep(.ant-switch){
        background-color: #eee;
        &:after{
            width: 30px;
            height: 30px;
            background-color: #bbb;
            margin-left: -10px;
            margin-top: -6px;
        }
    }
    :deep(.ant-switch-checked){
        background-color: rgb(252, 222, 193);
        &:after{
            margin-left: 5px;
            background-color: rgb(244, 145, 51);
        }
    }
    :deep(.ant-form-explain){
        padding-left: 140px;
    }
    .content{
        :deep(.ant-form-explain){
            padding-left: 0;
        }
    }
}
.page{
    padding: 10px;
    /deep/.ant-pagination-simple-pager{
        display: none;
    }
    :deep(.ant-pagination-jump-next),
    :deep(.ant-pagination-jump-prev),
    :deep(.ant-pagination-item),
    :deep(.ant-pagination-prev), 
    :deep(.ant-pagination-next){
        width: 30px;
        height: 30px !important;
        line-height: 30px !important;
        margin-left: 10px;
        margin-right: 0 !important;
    }
}


</style>